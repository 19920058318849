@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot?luh5yk');
  src: url('./assets/fonts/icomoon.eot?luh5yk#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?luh5yk') format('truetype'),
    url('./assets/fonts/icomoon.woff?luh5yk') format('woff'),
    url('./assets/fonts/icomoon.svg?luh5yk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.otpInputStyle {
  border: 1px solid #838aa3;
  width: 50px !important;
  height: 3.7rem;
  margin: 0 8px;
  border-radius: 5px;
}
.icon-Group-16592 .path1:before {
  content: '\e900';
}
.icon-Group-16592 .path2:before {
  content: '\e901';
  color: #fff;
  margin-left: -1em;
}

.icon-instagram .path1:before {
  content: '\e902';
}
.icon-instagram .path2:before {
  content: '\e903';
  color: #fff;
  margin-left: -1em;
}

.icon-linkedin .path1:before {
  content: '\e904';
}
.icon-linkedin .path2:before {
  content: '\e905';
  color: #fff;
  margin-left: -1em;
}

.icon-twitter .path1:before {
  content: '\e906';
  color: #47b462;
}
.icon-twitter .path2:before {
  content: '\e907';
  color: #fff;
  margin-left: -1em;
}

.icon-ayurveda:before {
  content: '\e938';
}
.icon-dentist:before {
  content: '\e939';
}
.icon-dietitian:before {
  content: '\e93a';
}
.icon-gynecologist:before {
  content: '\e93b';
}
.icon-homeopathy:before {
  content: '\e93c';
}
.icon-messageWrite:before {
  content: '\e93d';
}
.icon-physician:before {
  content: '\e93e';
}
.icon-sending:before {
  content: '\e93f';
}
.icon-share:before {
  content: '\e940';
}
.icon-smile:before {
  content: '\e941';
}
.icon-account:before {
  content: '\e935';
}
.icon-accountSettings:before {
  content: '\e936';
}
.icon-lock:before {
  content: '\e937';
}
.icon-doctor1:before {
  content: '\e92b';
}
.icon-fastfood:before {
  content: '\e92c';
}
.icon-first-aid-kit:before {
  content: '\e92d';
}
.icon-pharmacy:before {
  content: '\e92e';
}
.icon-heartbeat:before {
  content: '\e92f';
}
.icon-injection:before {
  content: '\e930';
}
.icon-pills:before {
  content: '\e931';
}
.icon-refer:before {
  content: '\e932';
}
.icon-schedule:before {
  content: '\e933';
}
.icon-sendMessage:before {
  content: '\e934';
}
.icon-mail:before {
  content: '\e92a';
}
.icon-notification:before {
  content: '\e91f';
}
.icon-settings:before {
  content: '\e925';
}
.icon-addfile:before {
  content: '\e900';
}
.icon-appointment:before {
  content: '\e901';
}
.icon-attachment:before {
  content: '\e902';
}
.icon-calendar:before {
  content: '\e903';
}
.icon-call:before {
  content: '\e904';
}
.icon-chat:before {
  content: '\e905';
}
.icon-check:before {
  content: '\e906';
}
.icon-clock:before {
  content: '\e907';
}
.icon-close:before {
  content: '\e908';
}
.icon-comment:before {
  content: '\e909';
}
.icon-configuration:before {
  content: '\e90a';
}
.icon-delete:before {
  content: '\e90b';
}
.icon-doctor:before {
  content: '\e90c';
}
.icon-download:before {
  content: '\e90d';
}
.icon-dropArrow:before {
  content: '\e90e';
}
.icon-edit:before {
  content: '\e90f';
}
.icon-editnote:before {
  content: '\e910';
}
.icon-eye:before {
  content: '\e911';
}
.icon-eyeOutline:before {
  content: '\e912';
}
.icon-folder:before {
  content: '\e913';
}
.icon-globe:before {
  content: '\e914';
}
.icon-group:before {
  content: '\e915';
}
.icon-heart-outline:before {
  content: '\e916';
}
.icon-heart:before {
  content: '\e917';
}
.icon-home:before {
  content: '\e918';
}
.icon-list:before {
  content: '\e919';
}
.icon-logout:before {
  content: '\e91a';
}
.icon-members:before {
  content: '\e91b';
}
.icon-message:before {
  content: '\e91c';
}
.icon-meter:before {
  content: '\e91d';
}
.icon-more:before {
  content: '\e91e';
}
.icon-option:before {
  content: '\e920';
}
.icon-patient:before {
  content: '\e921';
}
.icon-plus:before {
  content: '\e922';
}
.icon-report:before {
  content: '\e923';
}
.icon-search:before {
  content: '\e924';
}
.icon-shotOption:before {
  content: '\e926';
}
.icon-target:before {
  content: '\e927';
}
.icon-time:before {
  content: '\e928';
}
.icon-user:before {
  content: '\e929';
}

.styles-module_dark__xNqje {
  background-color: white !important;
  color: black !important;
  padding: 0px;
  margin-top: 17px !important;
  box-shadow: 0px 0px 5px 0px rgba(74, 190, 130, 0.3);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(74, 190, 130, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(74, 190, 130, 0.3);
}

.styles-module_show__2NboJ {
  opacity: 0.95 !important;
}

.styles-module_tooltip__mnnfp {
  padding: 0px !important;
}

.footer-bg {
  width: 100%;
  padding: 200px 0 0;
  background: url('/src/assets/images/footer-new-bg.png') no-repeat center center / cover;
}

@media screen and (max-width: 700px) {
  .footer-bg {
    padding: 280px 0 0;
  }
}

.PhoneInputInput {
  outline: none;
  background-color: transparent;
}

.ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #fbfbfb;
  border: 0.4px solid #ccc !important;
}
.ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fbfbfbb9;
  border: 0.2px solid #ccc !important;
}

.faq-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.faq-title {
  border-bottom: 1px solid #ccc !important;
}

.css-1wq3uko-MuiPaper-root-MuiAccordion-root:before {
  background-color: 'none' !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.custom-tooltip {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid #e8e8e8 !important;
  opacity: 0.99 !important;
}
